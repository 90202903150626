import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["textarea"];

  connect() {
    // Set initial height for the textarea
    this.adjustTextareaHeight({ target: this.textareaTarget });
  }

  adjustTextareaHeight(event) {
    const textarea = event.target;

    // Reset height to auto to get the correct scrollHeight
    textarea.style.height = "auto";

    // Set the height to match the content
    textarea.style.height = textarea.scrollHeight + "px";
  }
}
